import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

export function ConfigureEmails({ emailAddresses, onHide, onSave }) {
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    setEmails(
      (emailAddresses || '')
        .split(',')
        .map(e => e.trim().toLocaleLowerCase())
        .filter(Boolean)
    );
  }, [emailAddresses]);
  function addEmail(email) {
    if (validEmail(email)) {
      setEmails([...emails, email.trim()]);
      setNewEmail('');
    }
  }
  function removeEmail(index) {
    setEmails([...emails.filter((e, idx) => idx !== index)]);
  }
  function validEmail(str) {
    return /^.+@.+$/.test(str) && emails.every(e => e !== str.trim());
  }
  function handleEnter(e) {
    if (e.which === 13) {
      addEmail(newEmail);
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    addEmail(newEmail);
  }
  const matchingEmails = emails.filter(
    e => !searchText || e.indexOf(searchText) > -1
  );
  return (
    <Modal show={emailAddresses != null} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Configure email addresses</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit} onKeyUp={handleEnter}>
        <Modal.Body style={{ maxHeight: '50vh', overflowY: 'auto' }}>
          <Form.Group controlId="search">
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={e =>
                  setSearchText(e.target.value.toLocaleLowerCase())
                }
              />
              <InputGroup.Append>
                <Button
                  type="button"
                  variant="outline-secondary"
                  onClick={() => setSearchText('')}
                  style={{ width: 100 }}
                  disabled={!searchText.length}
                >
                  Clear
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <hr />
          {matchingEmails.map((email, index) => (
            <Form.Group key={email} controlId={`emails[${index}]`}>
              <InputGroup>
                <Form.Control type="email" defaultValue={email} readOnly />
                <InputGroup.Append>
                  <Button
                    type="button"
                    variant="outline-danger"
                    onClick={() => removeEmail(index)}
                    style={{ width: 100 }}
                  >
                    Remove
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          ))}
          <Form.Group controlId="newEmail">
            <InputGroup>
              <Form.Control
                type="email"
                value={newEmail}
                onChange={e => setNewEmail(e.target.value.toLocaleLowerCase())}
                required
              />
              <InputGroup.Append>
                <Button
                  type="submit"
                  variant="outline-primary"
                  style={{ width: 100 }}
                >
                  Add
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          {searchText.length > 0 && !matchingEmails.length && (
            <Alert variant="warning">
              No emails matching <b>{searchText}</b>.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="danger" onClick={onHide}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={() => onSave(emails.join(', '))}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
