import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import delve from 'dlv';

export function SelectConfig({
  ctx: { 
    caseInfo, 
    phoneNumbers, 
    senders },
  show,
  onSelect
}) {
  //const isOnlyOneCourt = delve(courts, 'length', 0) === 1;
  const isOnlyOneCourt = true;
  //const defaultCourt = isOnlyOneCourt ? delve(courts, '0.itemId.native') : '';
  const defaultCourt = caseInfo.courtname;
  const isOnlyOneSender = delve(senders, 'length', 0) === 1;
  const defaultSender = isOnlyOneSender ? senders[0] : '';
  const isOnlyOnePhoneNumber = delve(phoneNumbers, 'length', 0) === 1;
  const defaultPhoneNumber = isOnlyOnePhoneNumber ? phoneNumbers[0] : '';
  const [theCourt, setTheCourt] = useState(defaultCourt);
  const [sender, setSender] = useState(defaultSender);
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);
  const [memo, setMemo] = useState('');
  const [yourEmail, setYourEmail] = useState('');
  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    //const caseInfo = courts.find(c => c.itemId.native === court);
    const caseInfo = theCourt;
    onSelect({
      theCourt,
      sender,
      phoneNumber,
      memo,
      yourEmail,
      caseInfo: caseInfo ? caseInfo : null
    });
  }
  return (
    <Modal show={show} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Service email configuration</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="court">
            <Form.Label>Court</Form.Label>
            <Form.Control
              as="select"
              value={theCourt}
              onChange={e => setTheCourt(e.target.value)}
              disabled={isOnlyOneCourt}
              required
            >
              <option value="">- Select a court -</option>
              <option key={theCourt} value={theCourt}>
                  {theCourt}
                </option>
              {/* {courts.map(court => (
                <option key={court.itemId.native} value={court.itemId.native}>
                  {court.dataObject.courtname}
                </option>
              ))} */}
            </Form.Control>
            {theCourt === '' && (
              <Form.Text className="alert alert-danger">
                There is no court available!
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="sender">
            <Form.Label>Sender</Form.Label>
            <Form.Control
              as="select"
              value={sender}
              onChange={e => setSender(e.target.value)}
              disabled={isOnlyOneSender}
              required
            >
              <option value="">- Select a sender -</option>
              {senders.map(sender => (
                <option key={sender} value={sender}>
                  {sender}
                </option>
              ))}
            </Form.Control>
            {senders.length === 0 && (
              <Form.Text className="alert alert-danger">
                There are no senders available!
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="phoneNumber">
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              as="select"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              disabled={isOnlyOnePhoneNumber}
              required
            >
              <option value="">- Select a phone number -</option>
              {phoneNumbers.map(phoneNumber => (
                <option key={phoneNumber} value={phoneNumber}>
                  {phoneNumber}
                </option>
              ))}
            </Form.Control>
            {phoneNumbers.length === 0 && (
              <Form.Text className="alert alert-danger">
                There are no phone numbers available!
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="memo">
            <Form.Label>Type of document(s)</Form.Label>
            <Form.Control
              type="text"
              value={memo}
              onChange={e => setMemo(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="yourEmail">
            <Form.Label>Your email</Form.Label>
            <Form.Control
              type="email"
              value={yourEmail}
              onChange={e => setYourEmail(e.target.value)}
            />
            <Form.Text className="alert alert-info">
              Enter your email address to receive a copy of the service email.
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Generate</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
