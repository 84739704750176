import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

export function SelectFilevineDocuments({ show, onHide, onSelect, origDocs, filteredDocs, onDocUpdate }) {
  
  const docs =
      !!filteredDocs && Array.isArray(filteredDocs)
      ? filteredDocs.map(doc => ({
          filevineDocumentId: doc.documentId.native,
          id: doc.documentId.native.toString(),
          name: doc.filename
        }))
      : [];

  const [selected, setSelected] = useState([]);
    function preventSubmit(e) {
    e.preventDefault();
  }
  function selectDoc(docId) {
    setSelected([...selected, docId]);
  }
  function unselectDoc(docId) {
    setSelected([...selected.filter(id => id !== docId)]);
  }
  function filterDocs(e){

    if(e.keyCode === 13){
      e.preventDefault();
    }

    const searchVal = e.target.value.toLowerCase().trim();
    if(searchVal.length > 1){
      const docsFilterApplied = origDocs.filter(d => d.filename.toLowerCase().indexOf(searchVal) > -1);
      onDocUpdate(docsFilterApplied);
    }
    else {
      onDocUpdate(origDocs);
    }
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select Filevine documents</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Form onSubmit={preventSubmit}>
          <Form.Group>
            <div className="search">
              <Form.Control 
                type="text"
                name="searchText"
                id="searchText"
                placeholder="Search for documents.."
                onKeyUp={filterDocs}>
              </Form.Control>
            </div>
          </Form.Group>
          <ListGroup variant="flush">
            {docs.map(doc => (
              <ListGroup.Item key={doc.id}>
                <label
                  htmlFor={doc.id}
                  className="custom-control custom-checkbox mb-0"
                >
                  <input
                    type="checkbox"
                    name={doc.id}
                    id={doc.id}
                    className="custom-control-input"
                    checked={selected.some(id => id === doc.id)}
                    onChange={e =>
                      e.target.checked ? selectDoc(doc.id) : unselectDoc(doc.id)
                    }
                  />
                  <span className="custom-control-label">{doc.name}</span>
                </label>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() =>
            onSelect(docs.filter(d => selected.indexOf(d.id) > -1))
          }
          disabled={!selected.length}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
