import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import delve from 'dlv';
import Spinner from 'react-bootstrap/Spinner';
import { ReactComponent as Logo } from './logo-white.svg';

import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css';
import './index.css';

import { ConfigureEmails } from './ConfigureEmails';
import { SelectFilevineDocuments } from './SelectFilevineDocuments';
import { SelectConfig } from './SelectConfig';

function App() {
  const [isSending, setIsSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [ctx, setCtx] = useState(false);
  const [toError, setToError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [contentPadding, setContentPadding] = useState(0);
  const [showConfig, setShowConfig] = useState(true);
  const [showFilevineDocs, setShowFilevineDocs] = useState(false);
  const [to, setTo] = useState('');
  const [cc, setCc] = useState('');
  const [bcc, setBcc] = useState('');
  const [yourEmail, setYourEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [memo, setMemo] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [origDocs, setOrigDocs] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);

  const navbarEl = useRef(null);
  const fileUploadEl = useRef(null);

  const query = new URLSearchParams(window.location.search);
  const projectId = query.get('projectid');
  const projectEmail = query.get('projectemail');

  function triggerFileUploadDialog() {
    fileUploadEl.current.click();
  }

  function updateAttachments(data) {
    setAttachments(data);
    setCanSend(data.length > 0 && to && !subjectError && body);
  }

  function addFilevineAttachments(docs) {
    updateAttachments([...attachments, ...docs]);
    setShowFilevineDocs(false);
  }

  function addAttachments(e) {
    if (e.target.files.length) {
      const newDocs = [...e.target.files].map(f => {
        f.filevineDocumentId = null;
        return f;
      });
      updateAttachments([...attachments, ...newDocs]);
    }
  }

  function removeAttachment(index) {
    updateAttachments([
      ...attachments.slice(0, index),
      ...attachments.slice(index + 1)
    ]);
  }

  async function generateServiceEmail(config) {
    setShowConfig(false);

    setYourEmail(config.yourEmail);

    const to = ctx.parties.join(', ');
    setToError(to.length === 0);

    setTo(to);

    //const caseNumber = delve(config, 'caseInfo.casenumber');
    const caseNumber = delve(ctx, 'caseInfo.casenumber');
    //const fileNumber = delve(ctx, 'caseSummary.file');
    const fileNumber = ctx.fileNumber;

    setSubjectError(!caseNumber || !fileNumber);

    setSubject(
      `SERVICE OF COURT DOCUMENTS - ${caseNumber} (File #: ${fileNumber})`
    );

    const courtName = delve(ctx, 'caseInfo.courtname', '')
      .replace(/\n/g, ' ')
      .replace(/\s{2,}/g, ' ');
    // const courtName = delve(config, 'caseInfo.courtname', '')
    //   .replace(/\n/g, ' ')
    //   .replace(/\s{2,}/g, ' ');
    // const plaintiff = delve(config, 'caseInfo.plaintiff');
    const plaintiff = delve(ctx, 'caseInfo.plaintiff');
    // const defendant = delve(config, 'caseInfo.defendant');
    const defendant = delve(ctx, 'caseInfo.defendant');

    setMemo(config.memo);
    setBody(`${courtName}
${caseNumber}
${plaintiff} v. ${defendant}
${config.memo}

Sent by: ${config.sender} ${config.phoneNumber}`);

    setCanSend(
      attachments.length > 0 && to && caseNumber && fileNumber && body
    );
  }

  async function sendServiceEmail(docs) {
    // auto-bcc the project email and the sending user
    const augmentedBcc = [projectEmail, yourEmail]
      .concat(
        bcc
          .split(',')
          .map(email => email.trim())
          .filter(Boolean)
      )
      .filter(Boolean)
      .join(', ');

    const res = await fetch(
      `${process.env.REACT_APP_SERVICE_URL}/service-email`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          projectId,
          to,
          cc,
          bcc: augmentedBcc,
          subject,
          body,
          memo,
          docs
        })
      }
    );

    if (!res.ok) {
      throw new Error(`${res.status} - ${res.statusText}`);
    }
  }

  async function createAndUploadDocument(att) {
    if (att.filevineDocumentId) {
      return { name: att.name, documentId: att.filevineDocumentId };
    }

    console.log('creating', att.name);
    const res = await fetch(`${process.env.REACT_APP_SERVICE_URL}/upload`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        name: att.name,
        size: att.size,
        type: att.type,
        projectId
      })
    });

    if (!res.ok) {
      throw new Error('Failed to create: ' + att.name);
    }

    const {
      documentId: { native: documentId },
      contentType,
      url
    } = await res.json();

    console.log('uploading', att.name, 'to', url, 'contentType', contentType);
    const uploadRes = await fetch(url, {
      method: 'PUT',
      headers: { 'content-type': contentType },
      body: att
    });

    if (!uploadRes.ok) {
      throw new Error('Failed to upload: ' + att.name);
    }

    console.log('created + uploaded!', att, documentId);
    return { name: att.name, documentId, isNew: true };
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      if (showFilevineDocs)
      {
        // if the select documents modal is open then don't submit the form
        return false;
      }
      else{
        setIsSending(true);
        setIsError(false);
  
        const docs = await Promise.all(attachments.map(createAndUploadDocument));
  
        await sendServiceEmail(docs);
  
        setSent(true);  
      }
    } catch (error) {
      console.error('problem:', error);
      setIsError(true);
    }
  }

  useEffect(() => {
    const setSelectionDocs = (ctxDocs) => {
      const beginningDocs = [];
      const filteredCtxDocs = [];
      for(const doc of ctxDocs){
        beginningDocs.push(createDoc(doc));
        filteredCtxDocs.push(createDoc(doc));
      }
      setOrigDocs(beginningDocs);
      setFilteredDocs(filteredCtxDocs);
    }
    const createDoc = (doc) => {
      const newDoc = {};
      newDoc.documentId = {};
      newDoc.documentId.native = doc.documentId.native;
      newDoc.filename = doc.filename;
      return newDoc;
    }
    const fetchContext = async () => {
      try {
        setIsLoading(true);
        setIsError(false);
        setCtx(null);
        setOrigDocs([]);
        setFilteredDocs([]);

        const contextUrl = `${process.env.REACT_APP_SERVICE_URL}/context${window.location.search}`;
        const res = await fetch(contextUrl);
        if (!res.ok) {
          throw new Error(`${res.status} - ${res.statusText}`);
        }

        const ctxResponse = await res.json();
        setSelectionDocs(ctxResponse.docs);
        setCtx(ctxResponse);

      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (projectId) {
      fetchContext();
    }

    if (navbarEl.current) {
      setContentPadding(navbarEl.current.getBoundingClientRect().height + 16);
    }
  }, [projectId]);

  if (!projectId || isError) {
    return (
      <div className="container">
        <div className="animated fadeIn alert alert-danger my-4">
          Something has gone wrong. Please provide the Filevine SAT team with
          the following URL: {window.location.toString()}
        </div>
      </div>
    );
  }

  if (sent) {
    return (
      <div className="container">
        <div className="animated fadeIn alert alert-success my-4">
          Successfully added to queue. Delivery confirmation will appear in the{' '}
          <a
            className="alert-link"
            href={`https://app.filevine.com/#/project/${projectId}`}
          >
            project activity
          </a>
          .
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="min-vh-100">
      <SelectFilevineDocuments
        show={showFilevineDocs}
        onHide={() => setShowFilevineDocs(false)}
        onSelect={addFilevineAttachments}
        origDocs={origDocs}
        filteredDocs={filteredDocs}
        onDocUpdate={setFilteredDocs}
      />
      <nav ref={navbarEl} className="navbar fixed-top navbar-dark bg-dark">
        <div className="container">
          <a
            href="https://app.filevine.com"
            className="navbar-brand d-flex align-items-center"
          >
            <Logo /> <span className="pl-2">Service Email</span>
          </a>

          <button
            type="submit"
            className={`animated btn btn-primary ${canSend ? 'tada' : ''}`}
            disabled={!canSend || isSending}
          >
            {isSending ? (
              <span className="d-flex align-items-center">
                <Spinner animation="border" size="sm" />
                <span className="ml-2">Sending...</span>
              </span>
            ) : (
              'Send'
            )}
          </button>
        </div>
      </nav>
      {isLoading || !ctx ? (
        <span className="d-flex w-100 vh-100 align-items-center justify-content-center">
          <span className="spinner" />
        </span>
      ) : (
        <div className="container" style={{ paddingTop: contentPadding }}>
          <SelectConfig
            ctx={ctx}
            show={showConfig}
            onSelect={generateServiceEmail}
          />
          <ConfigureEmails
            emailAddresses={
              emailAddresses === 'to'
                ? to
                : emailAddresses === 'cc'
                ? cc
                : emailAddresses === 'bcc'
                ? bcc
                : null
            }
            onHide={() => setEmailAddresses(null)}
            onSave={emails => {
              const handler =
                emailAddresses === 'to'
                  ? setTo
                  : emailAddresses === 'cc'
                  ? setCc
                  : setBcc;
              handler(emails);
              setEmailAddresses(null);
            }}
          />

          <fieldset disabled={isSending}>
            <div className="form-group row">
              <div className="col-12 text-center lead">
                <b>
                  <a href={`https://app.filevine.com/#/project/${projectId}`}>
                    {ctx.project.projectOrClientName}
                  </a>
                </b>
                <hr />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="to" className="col-sm-2 col-form-label">
                To:
              </label>
              <div className="col-sm-10">
                {toError ? (
                  <small className="form-control-plaintext alert alert-danger p-2 mb-0">
                    There are no recipients for this service email. Please add
                    them in Filevine.
                  </small>
                ) : (
                  <div className="input-group">
                    <input
                      type="text"
                      name="to"
                      id="to"
                      className="form-control"
                      value={to}
                      onChange={e => setTo(e.target.value)}
                      readOnly
                      required
                    />
                    <div className="input-group-append">
                      <button
                        onClick={() => setEmailAddresses('to')}
                        type="button"
                        className="btn btn-secondary"
                      >
                        Configure
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="cc" className="col-sm-2 col-form-label">
                Cc:
              </label>
              <div className="col-sm-10">
                <div className="input-group">
                  <input
                    type="text"
                    name="cc"
                    id="cc"
                    className="form-control"
                    value={cc}
                    onChange={e => setCc(e.target.value)}
                    readOnly
                  />
                  <div className="input-group-append">
                    <button
                      onClick={() => setEmailAddresses('cc')}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Configure
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="bcc" className="col-sm-2 col-form-label">
                Bcc:
              </label>
              <div className="col-sm-10">
                <div className="input-group">
                  <input
                    type="text"
                    name="bcc"
                    id="bcc"
                    className="form-control"
                    value={bcc}
                    onChange={e => setBcc(e.target.value)}
                    readOnly
                  />
                  <div className="input-group-append">
                    <button
                      onClick={() => setEmailAddresses('bcc')}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Configure
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="subject" className="col-sm-2 col-form-label">
                Subject:
              </label>
              <div className="col-sm-10">
                {subjectError ? (
                  <small className="form-control-plaintext alert alert-danger p-2 mb-0">
                    The case number and/or file # is missing for this project.
                    Please add them in Filevine.
                  </small>
                ) : (
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    className="form-control"
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    readOnly
                    required
                  />
                )}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="body" className="col-sm-2 col-form-label">
                Body:
              </label>
              <div className="col-sm-10">
                <textarea
                  type="text"
                  name="body"
                  id="body"
                  className="form-control text-nowrap"
                  rows="8"
                  value={body}
                  onChange={e => setBody(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="attachments" className="col-sm-2 col-form-label">
                Attachments:
              </label>
              <div className="col-sm-10">
                <ul className="mb-0 list-group">
                  {attachments.length === 0 ? (
                    <li className="list-group-item text-muted">
                      No attachments
                    </li>
                  ) : (
                    attachments.map((attachment, index) => (
                      <li
                        key={`attachment.${index}`}
                        className="list-group-item d-flex align-items-baseline justify-content-between"
                      >
                        <span>
                          {attachment.name}
                          {attachment.filevineDocumentId ? '' : ' *'}
                        </span>
                        <button
                          type="button"
                          className="btn btn-link border-0 m-0 p-0 text-danger"
                          onClick={() => removeAttachment(index)}
                        >
                          Remove
                        </button>
                      </li>
                    ))
                  )}
                </ul>
              </div>
              {!attachments.length && (
                <div className="col-sm-10 offset-sm-2">
                  <small className="form-text alert alert-danger p-2 mb-0">
                    At least one attachment must be included!
                  </small>
                </div>
              )}
              {attachments.some(att => !att.filevineDocumentId) && (
                <div className="col-sm-10 offset-sm-2">
                  <small className="form-text alert alert-info p-2 mb-0">
                    * New uploads will also be associated with the project in
                    Filevine.
                  </small>
                </div>
              )}
            </div>
            <div className="form-group row mb-0">
              <div className="col-sm-5 offset-sm-2 mb-4 mb-sm-0">
                <button
                  type="button"
                  className="btn btn-block btn-secondary"
                  onClick={() => {setShowFilevineDocs(true); setFilteredDocs(origDocs)}}
                  disabled={!ctx.docs.length}
                >
                  Select Filevine documents
                </button>
              </div>
              <div className="col-sm-5">
                <button
                  type="button"
                  className="btn btn-block btn-secondary"
                  onClick={triggerFileUploadDialog}
                >
                  Upload new documents
                </button>
              </div>
            </div>
            <input
              ref={fileUploadEl}
              type="file"
              name="upload"
              id="upload"
              style={{ visibility: 'hidden', height: 0, width: 0 }}
              onChange={addAttachments}
              multiple
            />
          </fieldset>
        </div>
      )}
    </form>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
